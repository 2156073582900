import React from "react";
import {createStyles, makeStyles, Fab, Grid, Tooltip } from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import { useParams } from "react-router-dom";
import { GET_LIST_5W2H } from "../../../../services/options";
import useAxios from "../../../../Context/Hooks/useAxios";
import Table from "./Table";
import New5W2H from "./New"
import { ViewProjectContext } from "../..";
import { UserContext } from "../../../../Context/UserContext";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "auto",
    },
    table:{
      [theme.breakpoints.up("xs")]: { minHeight: 440},  
      [theme.breakpoints.up("sm")]: { minHeight: 440},  
      [theme.breakpoints.up("md")]: { minHeight: 440},  
      [theme.breakpoints.up("lg")]: { minHeight: 440},  
      [theme.breakpoints.up("xl")]: { minHeight: 640},   
    },
  })
);
 
export default function Tab5W2H() {
  const style = useStyles();
  const {id} = useParams()
  const [open, setOpen] = React.useState(false)
  const { privilege  } = React.useContext(UserContext);
  const {infosData} = React.useContext(ViewProjectContext);
  const {isCanceled} = infosData
  const {buffer, loading, error, request} = useAxios();

 const updateData = async () =>{
   await request(GET_LIST_5W2H(id)).then(r=> console.log(r))
 }

  React.useEffect(()=>{
    updateData()
  },[id])

  return (
  <>  
  <New5W2H open={open} setOpen={setOpen} handleUpdate={updateData}/>
   <Grid container spacing={1}>
    <Grid item xs={12} style={{ paddingBottom: 10 }}>
      <Tooltip placement="top" title="Novo 5W2H" aria-label="Novo 5W2H">
       <span>
        <Fab
         size="small"
         color="primary"
         aria-label="fab Novo 5W2H"
         onClick={() => setOpen(true)}
         disabled={privilege.cityHall || isCanceled}
        >
         <Icons.Add />
        </Fab>
       </span>
      </Tooltip>
    </Grid>
    <Grid item xs={12} className={style.table}>
      <Table data={buffer} loading={loading} error={error} updateData={updateData} />
    </Grid>
 </Grid>
 </>
 );
}
