import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Grid,
  createStyles,
  makeStyles,
  TextField,
} from "@material-ui/core";
 
import { SelectApi } from "../../../../Components/SelectsApi";
import { GET_GRAVITY, GET_URGENCY, GET_TREND, POST_UPDATE_GUT, GET_RQST_GUT } from "./../../../../services/options";
import api from "../../../../services/Api";
import { useSnackbar } from "notistack";
import { ButtonSuccess } from "../../../../Components/ButtonSuccess";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from "../../../../Components/DefaultModal";

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    display: "flex",
    [theme.breakpoints.up("xs")]: {width: 350, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("sm")]: {width: 380, padding: theme.spacing(0, 1, 0, 1),},   
    [theme.breakpoints.up("md")]: {width: 400, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("lg")]: {width: 400, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("xl")]: {width: 450, padding: theme.spacing(0, 1, 0, 1),},  
  },
})
);

const validationSchema = Yup.object({
  risk: Yup.string()
    .required("Obrigatório")
    .min(6, "O campo deve ter pelo menos 6 caracteres")
    .max(100, "Limite máximo de 100 caracteres"),
  gravity: Yup.object().shape().required("Obrigatório").nullable(),
  urgency: Yup.object().shape().required("Obrigatório").nullable(),
  trend: Yup.object().shape().required("Obrigatório").nullable(),
  gut: Yup.number().required(),
  average: Yup.number().required(),
});

export default function EditGUT({gutId, open, setOpen, updateData}) {
    const [init, setInit] = React.useState(null)
    const { enqueueSnackbar } = useSnackbar();
 
    const rqstInfo = async()=>{
     await api(GET_RQST_GUT(gutId)).then(r=>{
        const {data} = r
        setInit({
          id     : data.gutId,
          risk   : data.risk,
          gravity: data.gutGravity,
          urgency: data.gutUrgency,
          trend  : data.gutTrend,
          gut    : data.gut,
          average: data.average,
        })
      }).catch(e=>{
        enqueueSnackbar(`Erro ${e.message}`, { variant: "Error" })
      })
    }

  const submit = async (values, { setSubmitting }) => {
    const buffer = {
      gutId     : values.id,
      risk      : values.risk,
      gravityId : values.gravity.id,
      urgencyId : values.urgency.id,
      trendId   : values.trend.id,
      gut       : values.gut,
      average   : values.average,
    }

    console.log("mandou ", buffer)

     await api(POST_UPDATE_GUT(buffer)).then(r=>{
       const {data} = r;
       if (data.status === "success"){
        updateData()  
        setSubmitting(false);
        handleClose()
        enqueueSnackbar("GUT atualizado !!!", { variant: "success" });
       }else{
        setSubmitting(false);
        enqueueSnackbar(`Erro ao atualizar GUT`, { variant: "Error" })
       }     
      } 
      ).catch((e)=> {
       setSubmitting(false);
       enqueueSnackbar(`Erro ao atualizar GUT`, { variant: "Error" })}
      )
   };

   const handleClose =()=>{
    setOpen(false)
   }

    React.useEffect(()=>{
     if(open) rqstInfo()
    },[open])
 
  return ( 
   <DefaultModal 
    open={open} 
    handleClose={handleClose} 
    title="Editar GUT"
    loading={init === null}>
    <Formik
     setOpen={setOpen}
     initialValues={init}
     validationSchema={validationSchema}
     onSubmit={submit}>
     {(props) => <Forms {...props}/>}
    </Formik>
   </DefaultModal>
  );
}

const Forms = (props) => {
  const classes = useStyles();
  const {
    values: { risk, gravity, urgency, trend, status, gut },
    touched,
    errors,
    dirty,
    isSubmitting,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
  } = props;

  React.useEffect(() => {
    const start = Number(gravity?.id) * Number(urgency?.id) * Number(trend?.id);
    const calc  = Math.round((Number(gravity?.id) + Number(urgency?.id) + Number(trend?.id)) / 3);

    setFieldValue("gut", start || 0);
    setFieldValue("average", calc || 0);
  }, [gravity, urgency, trend]); // (G) x (U) x (T)

  return (
    <form onSubmit={handleSubmit} noValidate>
       <DefaultModalContent dividers> 
         <Grid container  className={classes.root} spacing={3}>
         <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            maxRows={5}
            variant="outlined"
            size="small"
            id="risk"
            label="Risco"
            name="risk"
            inputProps={{ autoComplete: "off" }}
            onChange={handleChange}
            value={risk}
            error={touched.risk && Boolean(errors.risk)}
            helperText={touched.risk && errors.risk}
          />
         </Grid> 
        <Grid item xs={12}>
          <SelectApi
            id="gravity"
            name="gravity"
            onChange={(e, value) => setFieldValue("gravity", value || null)}
            value={gravity}
            config={GET_GRAVITY()}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              variant: "outlined",
              label: "Gravidade",
            }}
            error={touched.gravity && Boolean(errors.gravity)}
            helperText={touched.gravity && errors.gravity}
          />
          </Grid>

          <Grid item xs={12}>
          <SelectApi
            id="urgency"
            name="urgency"
            onChange={(e, value) => setFieldValue("urgency", value || null)}
            value={urgency}
            config={GET_URGENCY()}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              variant: "outlined",
              label: "Urgência",
            }}
            error={touched.urgency && Boolean(errors.urgency)}
            helperText={touched.urgency && errors.urgency}
          />
          </Grid>
         <Grid item xs={12}>
          <SelectApi
            id="trend"
            name="trend"
            onChange={(e, value) => setFieldValue("trend", value || null)}
            value={trend}
            config={GET_TREND()}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              variant: "outlined",
              label: "Tendência",
            }}
            error={touched.trend && Boolean(errors.trend)}
            helperText={touched.trend && errors.trend}
          />
          </Grid>
         </Grid>
        </DefaultModalContent>
        <DefaultModalActions style={{marginRight:"0.5rem"}}>
          <ButtonSuccess
             style={{ width: 100 }}
             variant="contained"
             color="primary"
             type="submit"
             success={false}
             disabled={!dirty}
             loading={isSubmitting}
            > Salvar
            </ButtonSuccess>
        </DefaultModalActions>
    </form>
  );
};

EditGUT.defaultProps = {
  submit: (value) => {
    return true;
  },

  initialValues: {
    id: 0,
    risk: "",
    gravity: null,
    urgency: null,
    trend: null,
    gut: 0,
  },
};
