import React from "react";
import { convertToHTML } from "draft-convert";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Grid,
  createStyles,
  makeStyles,
  TextField,
  Fab,
  Tooltip,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import * as Icons from "@material-ui/icons";
 
import { TextEditor } from "../../../Components/Editor";
import { SelectApi } from "../../../Components/SelectsApi";
import {
  GET_RESPONSIBLESECRETARY,
  POST_UPDATE_SECRETARY,
} from "../../../services/options";
import { MaskPhone } from "../../../Components/Helper/FormatValues";
import ConfirmDelete from "./ConfirmDelete";
import { useSnackbar } from "notistack";
import api from "../../../services/Api";
import { ButtonSuccess } from "../../../Components/ButtonSuccess";
import { Box } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from "../../../Components/DefaultModal";

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    display: "grid",
    [theme.breakpoints.up("xs")]: {width: 380, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("sm")]: {width: 450, padding: theme.spacing(0, 1, 0, 1),},   
    [theme.breakpoints.up("md")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("lg")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("xl")]: {width: 520, padding: theme.spacing(0, 1, 0, 1),},  
  },
})
);

// const initialValues = {
//   name: "",
//   address: "",
//   phone: "",
//   email: "",
//   responsible: null,
//   description: "<p></p>",
//   status: 1,
// };

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Obrigatório"),
  address: Yup.string().required("Obrigatório"),
  phone: Yup.string().required("Obrigatório"),
  email: Yup.string().email("E-mail inválido").required("Obrigatório"),
  responsible: Yup.object().shape().required("Obrigatório").nullable(),
  // description: Yup.string().required("Obrigatório"),
});

export const GET_SECRETARYFORM_INFO = (id) => {
  return {
    method: "get",
    url: `secretary/description.php?id=${id}`,
  };
};

export default function EditSecretary({id, open, setOpen, updateData}) {
  const [info, setInfo] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const getData = async () => {
    await api(GET_SECRETARYFORM_INFO(id))
      .then((r) => {
        const buffer = r.data[0];
        setInfo({
          name: buffer.name,
          address: buffer.address,
          phone: buffer.phone,
          email: buffer.email,
          responsible: buffer.responsible,
          description: buffer.description,
          status: Number(buffer.status),
        });
      })
      .catch((e) => {});
  };

  const submit = async (values, { setSubmitting }) => {
    const buffer = {
      id: id,
      name: values.name,
      address: values.address,
      phone: values.phone,
      email: values.email,
      responsibleId: values.responsible.id,
      description: values.description,
      status: values.status,
    };

    await api(POST_UPDATE_SECRETARY(buffer))
      .then((r) => {
        //console.log(JSON.stringify(buffer, null, 2))
        const { data } = r;
        if (data.status === "success") {
          updateData();
          setSubmitting(false);
          Close();
          enqueueSnackbar("Secretaria atualizada !!!", { variant: "success" });
        } else {
          setSubmitting(false);
          enqueueSnackbar(`Erro ao atualizar secretaria!!`, {
            variant: "Error",
          });
        }
      })
      .catch((e) => {
        setSubmitting(false);
        enqueueSnackbar(`Erro ${e.message}`, { variant: "Error" });
      });
  };

  const Close = async () => {
    setOpen(false)
  };

  React.useEffect(() => {
    if (open) getData();
  }, [open]);

  return (
    <>
      <DefaultModal
        loading={info === null}
        open={open}
        handleClose={Close}
        title="Editar secretaria">
          <Formik
            initialValues={info}
            validationSchema={validationSchema}
            onSubmit={submit}
          >
            {(props) => <Forms {...props} />}
          </Formik>
      </DefaultModal>
    </>
  );
}

const Forms = (props) => {
  const classes = useStyles();
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = props;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <DefaultModalContent dividers >
        <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            maxRows={5}
            variant="outlined"
            size="small"
            id="name"
            label="Nome"
            name="name"
            autoFocus
            inputProps={{ autoComplete: "off" }}
            onChange={handleChange}
            value={values.name}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            id="email"
            label="email"
            name="email"
            inputProps={{ autoComplete: "off" }}
            onChange={handleChange}
            value={values.email}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            name="address"
            label="Endereço"
            id="address"
            inputProps={{ autoComplete: "off" }}
            onChange={handleChange}
            value={values.address}
            error={touched.address && Boolean(errors.address)}
            helperText={touched.address && errors.address}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            id="phone"
            label="Telefone"
            name="phone"
            InputProps={{ inputComponent: MaskPhone }}
            inputProps={{ autoComplete: "off" }}
            onChange={handleChange}
            value={values.phone}
            error={touched.phone && Boolean(errors.phone)}
            helperText={touched.phone && errors.phone}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectApi
            id="responsible"
            name="responsible"
            label="Responsável"
            onChange={(e, value) => {
              setFieldValue("responsible", value || null);
            }}
            value={values.responsible}
            config={GET_RESPONSIBLESECRETARY()}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              variant: "outlined",
              label: "Responsável",
            }}
            error={touched.responsible && Boolean(errors.responsible)}
            helperText={touched.responsible && errors.responsible}
          />
        </Grid>

        <Grid item xs={12}>
          <TextEditor
            id="description"
            name="description"
            label="Descrição"
            onChange={(event) => {
              setFieldValue(
                "description",
                convertToHTML(event.getCurrentContent())
              );
            }}
            value={values.description}
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description && errors.description}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Status</FormLabel>
            <RadioGroup
              aria-label="status"
              name="status"
              id="status"
              value={values.status}
              onChange={(e, value) => {
                setFieldValue("status", Number(value));
              }}
            >
             <Box style={{display: "flex"}}>  
              <FormControlLabel value={1} control={<Radio />} label="Ativo" />
              <FormControlLabel value={0} control={<Radio />} label="Inativo" />
             </Box>
            </RadioGroup>
          </FormControl>
        </Grid>
        </Grid>
        </DefaultModalContent>
         <DefaultModalActions style={{marginRight: "0.5rem"}}>
            <ButtonSuccess
              style={{ width: 120 }}
              variant="contained"
              color="primary"
              type="submit"
              success={false}
              loading={isSubmitting}
            >
              Salvar
            </ButtonSuccess>
        </DefaultModalActions>
    </form>
  );
};

/*Login:
  Nome:
  Secretaria:
  Email: por padrão ser o mesmo da secretaria responsável
  Status: Ativo / Inativo
  Nível: 
  Senha: 
  */
