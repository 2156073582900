import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core'
import React from 'react'
import { UserContext } from '../Context/UserContext';
import api from '../services/Api';
 

const Teste = () => {
  const {userInfo, privilege} = React.useContext(UserContext);
 

    async function getUser() {
       console.log(privilege)
      }


    return (
        <div>
          <Typography variant="h6"> teste</Typography>
        <Button onClick={getUser}>
            teste
        </Button>
        </div>
    )
}

export default Teste
