import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  createStyles,
  makeStyles,
  TextField,
  Grid,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from "../../../../Components/DefaultModal";
import { CustomDatePicker } from "../../../../Components/CustomDatePicker";
import { ButtonSuccess } from "../../../../Components/ButtonSuccess";
 
import { SelectApi } from "../../../../Components/SelectsApi";
import { NumberFormatCustom } from "../../../../Components/Helper/FormatValues";
import { GET_STATUS_TASK, POST_REQUEST_5W2H, POST_UPDATE_5W2H } from "../../../../services/options";
import api from "../../../../services/Api";

const GET_SITUATION = () => {
  return {
    method: "get",
    url: `loadSituation.php`,
  };
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("xs")]: {width: 250, padding: theme.spacing(0, 1, 0, 1),},
      [theme.breakpoints.up("sm")]: {width: 280, padding: theme.spacing(0, 1, 0, 1),},
      [theme.breakpoints.up("md")]: {width: 290, padding: theme.spacing(0, 1, 0, 1),},
      [theme.breakpoints.up("lg")]: {width: 300, padding: theme.spacing(0, 1, 0, 1),},
      [theme.breakpoints.up("xl")]: {width: 350, padding: theme.spacing(0, 1, 0, 1),},
    },
  })
);

const validationSchema = Yup.object({
  status: Yup.object()
    .shape({
      id: Yup.number().required(),
      name: Yup.string().required(),
    })
    .required("Obrigatório")
    .nullable(),
  executedDate: Yup.date().when("status.id", {
    is: 3,
    then: Yup.date().required("Obrigatório").nullable(),
  }),
  executedValue: Yup.number().when("status.id", {
    is: 3,
    then: Yup.number().required("Obrigatório").nullable(),
  }),
});

const initial = {
  id            : 0,
  risk          : null,
  whereField    : "",
  what          : "",
  why           : "",
  who           : "",
  initDate      : null,
  deadLine      : null,
  executedDate  : null,
  predictedValue: null,
  executedValue : null,
  how           : "",
  status        : null,
  canceled      : 0,
  stoped        : 0,
  justification : "",
 }

export function DialogUpdateInfos({id, open, setOpen, handleUpdate}) {
  const { enqueueSnackbar } = useSnackbar();
  const [init, setInit] = React.useState(null);

  const rqstInfo = async ()=>{
    await api(POST_REQUEST_5W2H({id: id})).then(r=>{
     const data = r.data[0];
     console.log(data)
     setInit(data)
    })
 }

  React.useEffect(() => {
    if (open) rqstInfo();
  }, [open]);

  const submit = async(values, { setSubmitting }) => {
    const buffer = {
      id            : values.id,
      riskId        : values.risk.id,
      what          : values.what,
      why           : values.why,
      whereField    : values.whereField,
      who           : values.who,
      initDate      : values.initDate,
      deadLine      : values.deadLine,
      executedDate  : values.executedDate,
      predictedValue: !values.predictedValue ? 0 : values.predictedValue,
      executedValue : !values.executedValue  ? 0 : values.executedValue,
      how           : values.how,
      statusId      : values.status.id,
      canceled      : 0,
      stoped        : 0,
      justification : values.justification,
    }  
   await api(POST_UPDATE_5W2H(buffer)).then(r=>{
     const {data} = r;
     console.log(JSON.stringify(buffer, null, 2))
     if(data.status === "success"){
      handleUpdate()
      setOpen(false);
      enqueueSnackbar("Plano de ação atualizado !!!", { variant: "success" });
     }else{
      setSubmitting(false);
      enqueueSnackbar(`Erro ao atualizar plano de ação`, { variant: "Error" })
     }
    }).catch(r=>{
      setSubmitting(false);
      enqueueSnackbar(`Erro ao atualizar plano de ação`, { variant: "Error" })
    })
  }

  const handleClose = () => {
    setOpen(false)
  };

  return (
    <DefaultModal
      open={open}
      handleClose={handleClose}
      loading={init === null}
      title="Atualizar plano de ação"
    >
     <Formik
      initialValues={init}
      validationSchema={validationSchema}
      onSubmit={submit}>
      {(props) => (<Forms  {...props} />)}
     </Formik>
    </DefaultModal>
  );
}

const Forms = (props) => {
  const classes = useStyles()
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    isSubmitting,
    setFieldValue,
    dirty,
  } = props;
  return (
    <form onSubmit={handleSubmit} noValidate>
      <DefaultModalContent dividers>
        <Grid container className={classes.root} spacing={3}>
          <Grid item xs={12}>
            <CustomDatePicker
              fullWidth
              id="executedDate"
              name="executedDate"
              label="Data de execução"
              onChange={(value) =>{
                setFieldValue("executedDate",  value || null)}}
              value={values.executedDate}
              error={touched.executedDate && Boolean(errors.executedDate)}
              helperText={touched.executedDate && errors.executedDate}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="executedValue"
              name="executedValue"
              label="valor executado"
              InputProps={{
                autoComplete: "off",
                inputComponent: NumberFormatCustom,
              }}
              onChange={handleChange}
              value={values.executedValue}
              error={touched.executedValue && Boolean(errors.executedValue)}
              helperText={touched.executedValue && errors.executedValue}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectApi
              fullWidth
              id="status"
              name="status"
              onChange={(e, value) => {
              
                  setFieldValue("status", value || null);

           
              }}
              value={values.status}
              config={GET_STATUS_TASK()}
              textFieldProps={{
                fullWidth: true,
                size: "small",
                variant: "outlined",
                label: "Status",
              }}
              error={touched.status && Boolean(errors.status)}
              helperText={touched.status && errors.status}
            />
          </Grid>
        </Grid>
      </DefaultModalContent>
      <DefaultModalActions style={{marginRight:"0.5rem"}}>
        <ButtonSuccess
          variant="contained"
          color="primary"
          type="submit"
          success={false}
          loading={isSubmitting}
          disabled={!dirty || isSubmitting}
        >
          Confirmar
        </ButtonSuccess>
      </DefaultModalActions>
    </form>
  );
};
