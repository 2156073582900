import React from 'react'; 
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Box, Fab, Tooltip, Typography, Dialog, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FormLoadingComponent from './FormLoading';

const styles = (theme) => ({
  root: {
    margin: 0,
   // display: "flex",
    padding: theme.spacing(2),
  },
  title:{
    textAlign:"start", 
    alignContent:"start",
   // textShadow: '1px 1px 1px #CCC',
    [theme.breakpoints.up("xs")]: { maxWidth: 360},   
    [theme.breakpoints.up("sm")]: { maxWidth: 450},    
    [theme.breakpoints.up("md")]: { maxWidth: 630},  
    [theme.breakpoints.up("lg")]: { maxWidth: 660},  
    [theme.breakpoints.up("xl")]: { maxWidth: 900},   
  },
  closeButton: {
    transform: 'scale(0.825)',
    display: "flex",
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, customTitle,  classes, onClose, actionsTitle, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
    {customTitle ? customTitle:
     <Typography variant="h5" className={classes.title}>{children}</Typography>}
 
     <Box style={{width: 150}}> 
     <Box className={classes.closeButton}>
     {actionsTitle ? actionsTitle: null} 
      {onClose ? (
       <Tooltip placement="top" title="Fechar janela" aria-label="Fechar janela">   
        <Fab size="small" color="primary" aria-label="close" onClick={onClose} style={{marginLeft: "1rem",}}>
          <CloseIcon fontSize="small"/>
        </Fab>
       </Tooltip>
      ) : null}
     </Box>  
     </Box>
     
    </MuiDialogTitle>
  );
});

export const DefaultModalContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export const DefaultModalActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function DefaultModal({open, handleClose, children, actionsTitle, title, scroll, customTitle, disableBackdropClick, loading,  ...others }) {
  const [openModal, setOpenModal] = React.useState(false)

  const closeModal = ()=>{
    setOpenModal(false)
    handleClose(false)
  }

  React.useEffect(()=>{
    setOpenModal(open)
  },[open])

  return (
      <Dialog 
      open={openModal} 
      onClose={closeModal} 
      aria-labelledby="default-modal" 
      scroll={scroll} 
      maxWidth="xl"
      disableBackdropClick={disableBackdropClick} 
      {...others}>
       {!loading ?
       <>
        <DialogTitle 
        id="default-modal-title"
        customTitle={customTitle}
        actionsTitle={actionsTitle} 
        onClose={handleClose}>
          {title}
        </DialogTitle>
          {children}
       </>:<FormLoadingComponent size={34}/>}   
      </Dialog>
  );
}

DefaultModal.defaultProps ={
  open: false,
  scroll: "body",
  disableBackdropClick: true,
  handleClose: ()=>{},
  loading: false,
}