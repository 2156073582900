import React from "react";
import {
  Grid,
  createStyles,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";

import { convertToHTML } from "draft-convert";
import { useSnackbar } from "notistack";
import { POST_INSERT_GROUP } from "../../../services/options";
import api from "../../../services/Api";
import { ButtonSuccess } from "../../../Components/ButtonSuccess";
import { TextEditor } from "../../../Components/Editor";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from "../../../Components/DefaultModal";
import { CustomDatePicker } from "../../../Components/CustomDatePicker";

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    display: "flex",
    [theme.breakpoints.up("xs")]: {width: 380, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("sm")]: {width: 450, padding: theme.spacing(0, 1, 0, 1),},   
    [theme.breakpoints.up("md")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("lg")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("xl")]: {width: 550, padding: theme.spacing(0, 1, 0, 1),},  
  },
})
);

const initialValues = {
  name: "",
  initDate: null,
  deadLine: null,
  description: "<p></p>",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Obrigatório"),
  initDate: Yup.date().required("Obrigatório").nullable().typeError("Data inválida"),
  deadLine: Yup.date()
    .min(
      Yup.ref("initDate"),
      "A data final não pode ser anterior à data de inícial"
    )
    .required("Obrigatório")
    .nullable("Obrigatório").typeError("Data inválida"),
  //description: Yup.string().required("Obrigatório").min(8, "Obrigatório"),
});

export default function NewGroup({open, setOpen, updateData}) {
  const { enqueueSnackbar } = useSnackbar();

  const submit = async (values, { setSubmitting }) => {
    const buffer = {
      name: values.name,
      initDate: values.initDate,
      deadLine: values.deadLine,
      description: values.description,
    };

    //console.log(JSON.stringify(buffer, null, 2))
    await api(POST_INSERT_GROUP(buffer)).then(r => {
      const {data} = r;
      if(data.status ==="success"){
        updateData();
        enqueueSnackbar("Portifólio criado !!!", { variant: "success" });
        setSubmitting(false);
        setOpen(false);
      }else{
        setSubmitting(false);
        enqueueSnackbar(`Erro`, { variant: "Error" });
      }
      }).catch(e => {
        setSubmitting(false);
        enqueueSnackbar(`Erro ${e.message}`, { variant: "Error" });
      });
  };

  return (
    <DefaultModal open={open} handleClose={()=>setOpen(false)} title="Novo portifólio">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {(props) => <Forms {...props} />}
      </Formik>
    </DefaultModal>
  );
}

const Forms = (props) => {
  const classes = useStyles();
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = props;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <DefaultModalContent dividers>
        <Grid container className={classes.root} spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            maxRows={5}
            variant="outlined"
            margin="dense"
            size="small"
            id="name"
            label="Nome"
            name="name"
            autoFocus
            inputProps={{ autoComplete: "off" }}
            onChange={handleChange}
            value={values.name}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
          />
        </Grid>

      
        <Grid item xs={6} >
          <CustomDatePicker
           fullWidth
           id="initDate"
           name="initDate"
           label="Data inicial"
       
           maxDate={values.deadLine || undefined}
           onChange={(value) =>{
           setFieldValue("initDate",  value || null )}}
           value={values.initDate}
           error={touched.initDate && Boolean(errors.initDate)}
           helperText={touched.initDate && errors.initDate}
          />
          </Grid>

          <Grid item xs={6}>
          <CustomDatePicker
            fullWidth
            id="deadLine"
            name="deadLine"
            label="Data limite"
     
            minDate={values.initDate || undefined}
            onChange={(value) =>{
            setFieldValue("deadLine",  value || null)}}
            value={values.deadLine}
            error={touched.deadLine && Boolean(errors.deadLine)}
            helperText={touched.deadLine && errors.deadLine}
           />
          </Grid>
    

        <Grid item xs={12}>
          <TextEditor
            id="description"
            name="description"
            label="Descrição"
            onChange={(event) => {setFieldValue("description", convertToHTML(event.getCurrentContent()))}}
            data={values.description}
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description && errors.description}
          />
        </Grid>
      </Grid>

        </DefaultModalContent>
        <DefaultModalActions style={{marginRight: "0.5rem"}}>
          <ButtonSuccess
             style={{width: 120}}
             variant="contained"
             color="primary"
             type="submit"
             success={false}
             loading={isSubmitting}
            > Criar
            </ButtonSuccess>
       </DefaultModalActions>  

    </form>
  );
};
