import React from "react";
import api from "./../services/Api";
import {GET_USER_INFOS, TOKEN_RQST} from "../services/options";
import {setToken, getToken, removeToken} from '../services/auth'
import { useNavigate } from "react-router-dom";

const initPrivilege ={
  administrator: false,
  collaboratorNv1: false,
  collaboratorNv2: false,
  cityHall: false,
 }

const initUser = {
  accessLevel: undefined,
  email: undefined,
  phone: undefined,
  cellPhone: undefined,
  id: undefined,
  lastAccess: undefined,
  login: undefined,
  name: "",
  secretary: undefined,
  status: undefined,
  numberLate: 0,
 }

export const UserContext = React.createContext();

export const UserStorage = ({children }) => {
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [login, setLogin] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState(initUser);
  const [privilege, setPrivilege] = React.useState(initPrivilege);
 
  const navigate = useNavigate();

  async function updateUserInfo(){
    const token = getToken()
    try{
      await getUser(token)
    }catch (e) {
      console.log(e.message)
    }
  }

  const userLogout = React.useCallback(async function () 
  {   setData(null);
      setError(null);
      setLoading(false);
      setLogin(false);
      setUserInfo(initUser);
      setPrivilege(initPrivilege)
     await removeToken()
      navigate("/auth");
    },[navigate]);

 async function getUser(token) {
 await api(GET_USER_INFOS(token)).then(r=>{
  const {data} = r
  if (data.status !== "error"){
   setUserInfo({...data, accessLevel: data.accessLevel.trim()})
   setPrivilege({
    administrator  : data.accessLevel.trim() === 'Administrador',
    collaboratorNv1: data.accessLevel.trim() === 'Gestor',
    collaboratorNv2: data.accessLevel.trim() === 'Colaborador',
    cityHall       : data.accessLevel.trim() === 'Prefeitura',
    manager        : data.accessLevel.trim() === 'Gerente',
   })
   setLogin(true)
}else{
 userLogout()
 throw new Error("Dados incorretos")
}
 }).catch(e=>{
   userLogout()
   throw new Error("Dados incorretos"); 
  })
 }

  async function userLogin({username, password}) {
    try {
      setError(null);
      setLoading(true);
      const {data} = await api(TOKEN_RQST({username, password}))
      console.log(data)
      if(data.status === "error") throw new Error(data.msg); 
      setToken(data.token)
      await getUser(data.token);
      navigate("/projects");
    } catch (e) {
      setError(e.message);
      setLogin(false);
      return false
    } finally {
      setLoading(false);
      return true
    }
  }

  React.useEffect(() => {
    async function autoLogin() {
      const token = getToken();
      if (token) {
        try {
          setError(null);
          setLoading(true);
          await getUser(token);
        } catch (e) {
          setError(e.message);
          userLogout();
        } finally {
          setLoading(false);
        }
      } else {
        setLogin(false);
      }
    }
    autoLogin();
  }, [userLogout]);

  return (
    <UserContext.Provider value={{userLogin, userLogout, data, error, loading, login, userInfo, privilege, updateUserInfo}}>
      {children}
    </UserContext.Provider>
  );
};