import React from "react";
import { useParams } from "react-router-dom";
import * as Icons from "@material-ui/icons";

import { createStyles, makeStyles, Grid, Fab, Tooltip  } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { POST_INSERT_GUT, GET_LIST_GUT } from "./../../../../services/options";
import { UserContext } from "../../../../Context/UserContext";
import useAxios from "../../../../Context/Hooks/useAxios";
import api from "./../../../../services/Api";
import Table from "./Table";
import NewGUT from "./NewGUT";
import { ViewProjectContext } from "../..";
import { ModalHelp } from "./Help";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "auto",
    },
    table:{
      [theme.breakpoints.up("xs")]: { minHeight: 440},  
      [theme.breakpoints.up("sm")]: { minHeight: 440},  
      [theme.breakpoints.up("md")]: { minHeight: 440},  
      [theme.breakpoints.up("lg")]: { minHeight: 440},  
      [theme.breakpoints.up("xl")]: { minHeight: 640},   
    },
  })
);

export default function TabGut() {
  const style = useStyles();
  const { id } = useParams();
  const { privilege } = React.useContext(UserContext);
  const {infosData} = React.useContext(ViewProjectContext);
  const {isCanceled} = infosData
  const [open, setOpen] = React.useState(false);
  const [help, setHelp] = React.useState(false);
  const {buffer, loading, error, request} = useAxios();
  const { enqueueSnackbar } = useSnackbar();

  const updateData = async () => {
    await request(GET_LIST_GUT(id))
  };

  React.useEffect(() => {
     updateData();  
  }, []);

  const submit = async (values, { setSubmitting }) => {
    const buffer = {
      projectId: id,
      risk     : values.risk,
      gravityId: !values.gravity?.id ? null:values.gravity.id,
      urgencyId: !values.urgency?.id ? null:values.urgency.id,
      trendId  : !values.trend?.id     ? null:values.trend.id,
      gut      : values.gut,
      average  : values.average,
    };
    //console.log(JSON.stringify(buffer, null, 2))
    await api(POST_INSERT_GUT(buffer)).then(r => {
        const {data} = r
        if(data.status === "success"){
          updateData();
          setSubmitting(false);
          setOpen(false);
          enqueueSnackbar("GUT criado !!!", { variant: "success" });
        }else{
          setSubmitting(false);
          enqueueSnackbar(`Erro ao criar o GUT`, { variant: "Error" });
        }
      })
      .catch((e) => {
        setSubmitting(false);
        enqueueSnackbar(`Erro ao criar o GUT`, { variant: "Error" });
      });
  };

  return (
  <>
   {open && <NewGUT submit={submit} open={open} setOpen={setOpen} title={"Novo GUT"}/>}
   <ModalHelp  open={help} close={()=> setHelp(false)}/>
    <Grid container className={style.root} spacing={1}>
      <Grid item xs={12} style={{padding: "0.5rem 0px", display: "flex", justifyContent: "space-between" }}>
        <Tooltip placement="top" title="Novo GUT" aria-label="Novo GUT">
          <span>
            <Fab
              size="small"
              color="primary"
              aria-label="Novo GUT"
              onClick={() => setOpen(true)}
              disabled={privilege.cityHall || isCanceled}
            >
              <Icons.Add />
            </Fab>
          </span>
        </Tooltip>

        <Tooltip placement="top" title="Ajuda" aria-label="help">
          <span>
            <Fab
              size="small"
              color="primary"
              aria-label="Ajuda"
              onClick={() => setHelp(true)}
            >
              <Icons.Help/>
            </Fab>
          </span>
        </Tooltip>
   
      </Grid>

      <Grid item xs={12} className={style.table}>
        <Table data={buffer} updateData={updateData} error={error} load={loading} />
      </Grid>
    </Grid>
  </>
  );
}