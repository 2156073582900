import axios from "axios";
import { getToken } from "./auth";
 
const isDev = false;
const BASE_URL = isDev ? "https://dev.draftmanagementproject.com/api/" : "https://homolog.draftmanagementproject.com/api/"

//"https://draftmanagementproject.com/api/"   //'https://back.draftmanagementproject.com/api/'

const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = getToken();
    const auth = token ? `Bearer ${token}` : '';
    config.headers.common['Authorization'] = auth;
    return config;
  },
  (error) => Promise.reject(error),
);

export default api;
 
 