import React from "react";
import { Link, useNavigate } from 'react-router-dom'

import clsx from 'clsx';
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Button,
  Grid,
  createStyles,
  makeStyles,
  Paper,
  TextField,
  Typography,
  InputAdornment,
  Divider,
  IconButton,
  Box,
  Avatar,
} from "@material-ui/core";

import { green, red } from '@material-ui/core/colors';

import {Lock, AccountCircle, Visibility, VisibilityOff} from '@material-ui/icons';
import { UserContext } from "../../Context/UserContext";
import { LinearProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";
 
const useStyles = makeStyles((theme) =>
createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      minHeight: '100vh',
      gap: theme.spacing(5),
      padding: theme.spacing(2),
      backgroundColor: '#FFFFFF',
    },
    sloganTitle: {
      marginBottom: theme.spacing(2),
    },
    form: {
      padding: theme.spacing(2),
      width: "400px",
    },
    submit: {
      marginTop: theme.spacing(4),
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    buttonProgress: {
      color: red[500],
    },
    logos:{
      borderRadius: 0,
 
    }

  })
);

  export default function LoginForm() {
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [showPassword, setShowPassword] = React.useState(false);
    const { userLogin, error, loading, login } = React.useContext(UserContext);

    React.useEffect(()=>{
      if (login) return navigate("/projects");// qndo loga vai pra home/
    },[login])

    React.useEffect(()=>{
      if (error) enqueueSnackbar(error, { variant: "warning" });
    },[error])

    const buttonClassname = clsx({
      [classes.buttonSuccess]: loading,
    });
  
    const initialValues = {
      username: '',
      password: '',
    };
    
    const validationSchema = Yup.object({
      username: Yup.string()
    .required("Obrigatório")
    .min(6, "O login deve ter pelo menos 6 caracteres"),
      password: Yup.string()
    .required("Obrigatório")
    .min(8, "O a senha deve ter pelo menos 8 caracteres"),
    });
 
    const formik  =  useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
         userLogin(values); 
         !loading && formik.setSubmitting(false);
          },
        });

        return (
            <div className={classes.root}>
            <Box style={{marginRight: '2rem'}}>
             <img src='/Assets/img/logo.png' alt='SISTEMA DE GERENCIAMENTO DE PROJETOS MUNICIPAIS'/>
             <Box style={{display: "flex"}}>
              <img  src='/Assets/img/Logo Escritório de Projetos.png'   alt='logo' style={{width: 220}}/>
              <img  src='/Assets/img/Sec Governo e Prefeitura Logo.png' alt='logo' style={{width: 400}}/>
             </Box>
            </Box>
              <Paper className={classes.form} elevation={4}>
                <form noValidate onSubmit={formik.handleSubmit}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="username"
                    name="username"
                    label='Nome de usuario'
                    autoFocus
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.username}
                    error={formik.touched.username && Boolean(formik.errors.username)}
                    helperText={formik.touched.username && formik.errors.username}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="password"
                    label="Senha"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="current-password"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock/>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                  />
                  <Button
                    style={{marginTop: "0.2rem"}}
                    className={buttonClassname}
                    type="submit"
                    size="large"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={formik.isSubmitting}
                  >
                    Entrar
                  </Button>
                   {loading && <LinearProgress/>}            
                </form>
                <Divider className={classes.divider}/>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Link to="/auth/forgotpassword" passhref="true">
                      <Button color="primary" variant="text" fullWidth>
                        Esqueceu a senha?
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          );
        }
    