import React from 'react'
import * as Icons from "@material-ui/icons";
import { List, ListItem, Typography,  ListItemIcon, Divider, ListItemText, Grid } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles';
import DefaultModal, { DefaultModalContent } from "../../../../Components/DefaultModal"
import { FormatDate, FormatRealNumber } from '../../../../Components/Helper/FormatValues';
import { SpeedDial, SpeedDialAction } from '@material-ui/lab';
import { UserContext } from '../../../../Context/UserContext';
import { DialogUpdateInfos } from './UpdateInfos';
import { ViewProjectContext } from '../..';
import Edit5W2H from './Edit';
import { Cancel5W2H } from './Cancel';
import Pause5WH2 from './Pause';
import ReOpen5W2H from './ReOpen';
import Resume5W2H from './Resume';

const useStyles = makeStyles((theme) =>
createStyles({
  root:{
    margin: 0,
    padding: 0,
  },
  main:{
    [theme.breakpoints.up("xs")]: { width: 380},
    [theme.breakpoints.up("sm")]: { width: 400},
    [theme.breakpoints.up("md")]: { width: 450},  
    [theme.breakpoints.up("lg")]: { width: 500},
    [theme.breakpoints.up("xl")]: { width: 550},
  },
  list: {
    padding: "0.5rem 0.5rem 0.5rem 0.5rem",
    margin:  "0px",
  },
  speedDial:{
  //  display: "flex",
   // marginLeft:"-1.2rem",
   
   // transform: 'scale(0.825)',
  }
})
);

 const init={
  risk           : "TESTE TESTE TESTE TESTE TESTE TESTE ", //risco
  what           : "TESTE", //o que?
  why            : "TESTE", //por que?
  where          : "TESTE", //onde
  who            : "User", //quem
  initDate       : "10/10/2010", //inicio
  deadLine       : "10/10/2010",// previsão de termino
  executedDate   : "10/10/2010", //data de termino
  estimatedTime  : 10,
  predictedValue : "1", //quanto
  executedValue  : "1", //valor executado

  how            : "TESTE TESTETESTETESTETESTETESTE TESTE TESTETESTE TESTETESTETESTE TESTETESTETESTE TESTE", //como
  status         : "Atrasado",
  situation      : "Em andamento", //situação

  gravity : "TESTE",
  urgency : "TESTE",
  trend   : "TESTE",
  gut     : "TESTE",

  canceled: false,
  stoped  : false,
 }

const ViewModal = ({id, open, close, handleUpdate}) => {
  const classes = useStyles();
  const { privilege  } = React.useContext(UserContext);
  const {infosData} = React.useContext(ViewProjectContext);
  const {isCanceled} = infosData
  const [data, setData] = React.useState(null)
  const [select, setSelect] = React.useState(null)
  const [openView, setOpenView] = React.useState(false)
  const [openDial, setOpenDial] = React.useState(false);

 const allUpdate =()=>{
  handleUpdate()
  updateData()
 }

 const updateData = async()=>{
  setData(init)
 }

  React.useEffect(()=>{
   if(open) updateData()
  },[open])

  const dias = Math.abs(data?.estimatedTime) 
  const dataGUT = [
    {
      icon: <Icons.Warning />,
      text: "Gravidade: ",
      data: data?.gravity,
    },
    {
      icon: <Icons.Warning />,
      text: "Urgência: ",
      data: data?.urgency,
    },
    {
      icon: <Icons.Warning />,
      text: "Tendência: ",
      data: data?.trend,
    },
    {
      icon: <Icons.Feedback />,
      text: "GUT: ",
      data: data?.gut,
    },
  ]

  const dataInfo = [
    {
      icon: <Icons.Help />,
      text: "o que fazer?",
      data: data?.what,
    },
    {
      icon: <Icons.Help />,
      text: "Por que fazer?",
      data: data?.why,
    },
    {
      icon: <Icons.NotListedLocation />,
      text: "Onde será feito?",
      data: data?.where,
    },
    {
      icon: <Icons.Person />,
      text: "Quem irá fazer?",
      data: data?.who,
    },
    {
      icon: <Icons.Today/>,
      text: "Início: ",
      data: FormatDate(data?.initDate) ,
    },
    {
      icon: <Icons.DateRange/>,
      text: "Previsão de término: ",
      data: FormatDate(data?.deadLine) ,
    },
    {
      icon: <Icons.Update/>,
      text: "Tempo estimado: ",
      data: "10 dias",
    },
    {
      icon: <Icons.EventAvailable/>,
      text: "Data de execução: ",
      data: FormatDate(data?.executedDate) ,
    },
    {
      icon: <Icons.LocalAtm />,
      text: "Valor previsto ",
      data: FormatRealNumber(data?.predictedValue),
    },
    {
      icon: <Icons.AttachMoney />,
      text: "Valor executado ",
      data: FormatRealNumber(data?.executedValue),
    },
    {
      icon: <Icons.Help />,
      text: "Como será feito?",
      data: data?.how,
    },
    {
      icon: <Icons.CheckCircle/>,
      text: "Status: ",
      data: data?.status,
    }, 
    {
      icon: <Icons.Report />,
      text: "Situação: ",
      data: data?.situation,
    }, 
  ]

  const actions = [
    { icon:    <Icons.Edit />, 
      name:    'Atualizar plano de ação',
      option:   0, 
      hide:     data?.stoped || data?.canceled,   
      disabled: !privilege.administrator && !privilege.collaboratorNv1},

    { icon: <Icons.Settings />, 
      name: 'Administrador',
      option: 1, 
      hide:   data?.stoped || data?.canceled, 
      disabled: !privilege.administrator},  

    { icon: <Icons.PlayCircleFilled />, 
      name: 'Continuar plano de ação',
      option: 2, 
      hide:     !data?.canceled , 
      disabled: privilege.cityHall || privilege.collaboratorNv2},    

    { icon: <Icons.PlayCircleFilled />, 
      name: 'Resumir plano de ação',
      option: 3, 
      hide:    !data?.stoped, 
      disabled: privilege.cityHall || privilege.collaboratorNv2},   
      
    { icon: <Icons.Cancel/>, 
      name: 'Cancelar plano de ação',
      option: 4, 
      hide:   data?.stoped || data?.canceled,  
      disabled: privilege.cityHall || privilege.collaboratorNv2},
      
    { icon: <Icons.PauseCircleFilled/>, 
      name: 'Suspender plano de ação',
      option: 5, 
      hide:   data?.stoped || data?.canceled, 
      disabled: privilege.cityHall || privilege.collaboratorNv2},    
    ]

  const handleOpenView =(id)=>{
    setSelect(id)
    setOpenView(true)
  }

  const handleCloseView =()=>{
    setOpenView(false)
    setSelect(null)
  }
  
  const handlActionsOpen = (e) => {
    if(e.type === "mouseenter" || e.type === "click")
    setOpenDial(true);
  };

  const handlActionsClose = (e) => {//mouseleave
    if(e.type === "mouseleave")
    setOpenDial(false);
  };
 
  const getSelectContent = React.useCallback((select) => { 
    switch (select) {
      case 0: return <DialogUpdateInfos id={id} open={openView} setOpen={handleCloseView} handleUpdate={allUpdate}/>
      case 1: return <Edit5W2H          id={id} open={openView} setOpen={handleCloseView} handleUpdate={allUpdate}/>
      case 2: return <ReOpen5W2H        id={id} open={openView} setOpen={handleCloseView} handleUpdate={allUpdate}/>
      case 3: return <Resume5W2H        id={id} open={openView} setOpen={handleCloseView} handleUpdate={allUpdate}/>
      case 4: return <Cancel5W2H        id={id} open={openView} setOpen={handleCloseView} handleUpdate={allUpdate}/>
      case 5: return <Pause5WH2         id={id} open={openView} setOpen={handleCloseView} handleUpdate={allUpdate}/>
      default: return null;
    }
  }, [select]);
  return  (
  <>  
  {getSelectContent(select)}
  <DefaultModal open={open} handleClose={close} title={data?.risk} maxWidth="sm"> 
   <DefaultModalContent dividers className={classes.root}> 
   <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" className={classes.main} spacing={0}>
    <Grid item xs={12} style={{marginLeft: "1rem"}}>
     <span>
      <SpeedDial
        ariaLabel="SpeedDialClick"
        className={classes.speedDial}
        icon={ <Icons.Settings  />  }
         
        direction="right"
        FabProps={{size:"small", disabled: isCanceled}}
        onClose={handlActionsClose}
        onOpen={handlActionsOpen}
        open={openDial} 
        >
        {actions.filter(m=> !m.hide).map((action) => (
         <SpeedDialAction
      
          tooltipPlacement="top"
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          disabled={action.disabled}
          hidden={action.hide}
          onClick={()=>handleOpenView(action.option)}/>
        ))}
      </SpeedDial>  
     </span>
    </Grid>
    <Grid item xs={12} className={classes.list}>
      <List dense disablePadding>
        {dataInfo && dataInfo.filter(r=> r.data !== null).map((value) => (
          <ListItem  alignItems="start" key={value.text}>
           <ListItemIcon >{value.icon}</ListItemIcon>
           <ListItemText
           style={{alignItems: "start", textAlign:"start", margin: 0, padding: 0}}
           primary={<Typography variant="h6" component="span">{value.text}</Typography>}
           secondary={
            <Typography
              component="span"
              style={{marginLeft: "0.5rem" }}
              color="textSecondary"
            >
            {value.data} 
            </Typography>
          }/>
          </ListItem>
        ))}
      </List>
      <Divider style={{margin: "0.5rem 0px 0.5rem 0px"}} />
      <List dense disablePadding>
        {dataGUT && dataGUT.filter(r=> r.data !== null).map((value) => (
          <ListItem key={value.text}>
           <ListItemIcon >{value.icon}</ListItemIcon>
           <ListItemText
           style={{alignItems: "start", textAlign:"start", margin: 0, padding: 0}}
           primary={<Typography variant="h6" component="span">{value.text}</Typography>}
           secondary={
            <Typography
              component="span"
              style={{marginLeft: "0.5rem" }}
              color="textSecondary"
            >
            {value.data} 
            </Typography>
          }/>
          </ListItem>
        ))}
      </List>
      </Grid>
     </Grid>
    </DefaultModalContent>
  </DefaultModal>  
  </>
  )
}

export default ViewModal
