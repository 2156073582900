import React from "react";
import { useParams } from "react-router-dom";
import * as Icons from "@material-ui/icons";

import {
  makeStyles,
  Grid,
  Divider,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  CircularProgress,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";

import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@material-ui/lab";
 
import { ShowProjectStatus } from "../../../../Components/ShowInfos";
import { FormatDate, FormatRealNumber } from "../../../../Components/Helper/FormatValues";
import { GET_RQSTINFO_PROJECT } from "../../../../services/options";
import api from "../../../../services/Api";
import { ViewTextEditor } from "../../../../Components/Editor";
import { UserContext } from "../../../../Context/UserContext";
import { ViewProjectContext } from "../..";

import EditProject from "./Edit";
import CancelProject from "./Cancel";
import PauseProject from "./Pause";
import ReOpenProject from "./ReOpen";
import ResumeProject from "./Resume";

const useStyles = makeStyles((theme) => ({
  main: {
    minHeight: "500px",
    margin: "0px auto",
    alignItems: "start",
    [theme.breakpoints.up("xs")]: { width: 350},
    [theme.breakpoints.up("sm")]: { width: 700},
    [theme.breakpoints.up("md")]: { width: 900,  padding: theme.spacing(0, 1, 0, 1), },
    [theme.breakpoints.up("lg")]: { width: 1100, padding: theme.spacing(0, 1, 0, 1), },
    [theme.breakpoints.up("xl")]: { width: 1200, padding: theme.spacing(0, 1, 0, 1), },
  },
  loading: {
    minHeight: "100%",
    backgroundColor: "red",
    margin: "10px auto",
  },
  speedDial: {
 
  },
}));

export default function DescriptionPanel() {
  const classes = useStyles();
  const { id } = useParams();
  const [openDial, setOpenDial] = React.useState(false);
  const [select, setSelect] = React.useState(null)
  const [openView, setOpenView] = React.useState(false)

  const [data, setData] = React.useState(null);

  const { privilege } = React.useContext(UserContext);
  const { infosData } = React.useContext(ViewProjectContext);

  const updateData = React.useCallback(async () => {
    await api(GET_RQSTINFO_PROJECT(id))
      .then((r) => {
        const { data } = r;
        setData({
          projectId: id,
          responsible:          data.responsible.name,
          responsibleSecretary: data.responsibleSecretary.name,
          resume:         data.resume,
          description:    data.description,
          group:          data.group.name,
          axis:           data.axis.name,
          situation:      data.status === null ? 0 : Number(data.status),
          predictedValue: data.predictedValue === null ? 0 : Number(data.predictedValue),
          executedValue:  data.executeValue === null ? 0 : Number(data.executeValue),
          initDate:       data.initDate,
          deadLine:       data.deadLine,
          created:        data.createDate,
          updated:        data.alterDate,
          canceled:       data.canceled === "1" ? true : false,
          stoped:         data.stoped === "1" ? true : false,
          justification:  data.justification,
        });
      })
      .catch((e) => {
        setData(null);
      });
  }, [id]);

  React.useEffect(() => {
    if (data === null) updateData();
  }, [data]);

  const handleOpenView =(id)=>{
    setSelect(id)
    setOpenView(true)
  //  setHidden(true)
  }

  const handleCloseView =()=>{
    setOpenView(false)
    setSelect(null)
   // setHidden(false)
  }

  const getSelectContent = React.useCallback((select) => { 
    switch (select) {
      case 0: return <EditProject   open={openView} setOpen={handleCloseView} updateData={updateData} />
      case 1: return <ReOpenProject open={openView} setOpen={handleCloseView} updateData={updateData} />
      case 2: return <ResumeProject open={openView} setOpen={handleCloseView} updateData={updateData} />
      case 3: return <CancelProject open={openView} setOpen={handleCloseView} updateData={updateData} />
      case 4: return <PauseProject  open={openView} setOpen={handleCloseView} updateData={updateData} />
      default: return null;
    }
  }, [select]);

  const actions = [
    { icon: <Icons.Edit />, 
      name: 'Editar projeto',
      option: 0, 
      hide: data?.canceled || data?.stoped, 
      disabled: infosData.canceled || (!privilege.administrator && !privilege.collaboratorNv1)},

    { icon: <Icons.Cancel />, 
      name: 'Cancelar projeto',
      option: 3,
      hide: data?.canceled || data?.stoped,
      disabled: infosData.canceled || (!privilege.administrator && !privilege.collaboratorNv1)},

    { icon: <Icons.LockOpen />, 
      name: 'Abrir Projeto',
      option: 1, 
      hide: !data?.canceled, 
      disabled:infosData.canceled || (!privilege.administrator && !privilege.collaboratorNv1)},

    { icon: <Icons.PauseCircleFilled/>, 
      name: 'Suspender projeto',
      option: 4,
      hide: data?.canceled || data?.stoped, 
      disabled: infosData.canceled || (!privilege.administrator && !privilege.collaboratorNv1)},

    { icon: <Icons.PlayCircleFilled />, 
      name: 'Continuar projeto',
      option: 2, 
      hide: !data?.stoped, 
      disabled: infosData.canceled || (!privilege.administrator && !privilege.collaboratorNv1)},
  ];

  const handlActionsOpen = (e) => {
    if(e.type === "mouseenter" || e.type === "click")
    setOpenDial(true);
  };

  const handlActionsClose = (e) => {//mouseleave
    if(e.type === "mouseleave")
    setOpenDial(false);
  };

  return (
    <>
    {getSelectContent(select)}
        <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.main}>
        {data !== null ? (
          <>
            <Grid item xs={12} sm={11} md={11} lg={11} xl={11} >
            <SpeedDial
             ariaLabel="SpeedDialClick"
             className={classes.speedDial}
             icon={<SpeedDialIcon icon={<Icons.Settings/>}/>}
             direction="right"
             FabProps={{size:"small"}}
             onClose={handlActionsClose}
             onOpen={handlActionsOpen}
             open={openDial} 
             >
            {actions.filter(r=> !r.hide).map((action) => (
            <SpeedDialAction
            tooltipPlacement="top"
             key={action.name}
             icon={action.icon}
             tooltipTitle={action.name}
             disabled={action.disabled}
             hidden={action.disabled}
             onClick={()=>handleOpenView(action.option)}/>
        ))}
            </SpeedDial>
            </Grid>
            <Grid item xs={12} sm={4} md={5} lg={5} xl={5} style={{paddingLeft: "0.5rem", marginRight: "1rem"}}>
              {/* <Grid item xs={12} style={{ marginBottom: 30 }}>
                <CardResume resume={data.resume} />
              </Grid> */}

              <Grid item xs={12} style={{ marginBottom: 30 }}>
                <CardDescription description={data.description} />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={7} md={6} lg={6} xl={6}>
              <ShowInfos data={data} />
            </Grid>
          </>
           ):(<CircularProgress/>)}
        </Grid>
    </>
  );
}

const CardResume = ({ resume }) => {
  return (
    <Card style={{ width: "100%" }}>
      <Typography
        variant="h6"
        gutterBottom
        style={{ margin: "5px 0px 5px 10px" }}
      >
        Resumo
      </Typography>
      <Divider />
      <CardContent>
        <Typography variant="subtitle1" gutterBottom paragraph={true}>
          {resume}
        </Typography>
      </CardContent>
    </Card>
  );
};

const CardDescription = ({ description }) => {
  return (
    <Card style={{ width: "100%", minHeight: 200, maxHeight: 400 }}>
      <Typography variant="h6" style={{ margin: "5px 0px 5px 10px" }}>
        Objetivo
      </Typography>
      <Divider />
      <ViewTextEditor value={description} isTheme />
    </Card>
  );
};

const ShowInfos = ({ data }) => {
  const {
    responsibleSecretary,
    responsible,
    axis,
    group,
    situation,
    predictedValue,
    executedValue,
    initDate,
    deadLine,
    created,
    updated,
  } = data;

  const dataInfo = [
    {
      icon: <Icons.Person />,
      text: "Responsável: ",
      data: responsible,
    },
    {
      icon: <Icons.AccountBalance />,
      text: "Secretaria responsável: ",
      data: responsibleSecretary,
    },
    {
      icon: <Icons.GroupWork />,
      text: "Portifólio: ",
      data: group,
    },
    {
      icon: <Icons.ScatterPlot />,
      text: "Programa: ",
      data: axis,
    },
    {
      icon: <Icons.LocalAtm />,
      text: "Valor previsto: ",
      data: FormatRealNumber(predictedValue),
    },
    {
      icon: <Icons.AttachMoney />,
      text: "Valor executado: ",
      data: FormatRealNumber(executedValue),
    },
    {
      icon: <Icons.Event />,
      text: "Data inicial: ",
      data: FormatDate(initDate),
    },
    {
      icon: <Icons.EventAvailable />,
      text: "Data limite: ",
      data: FormatDate(deadLine),
    },
    {
      icon: <Icons.Update />,
      text: "Tempo estimado: ",
      data: null,
    },
    {
      icon: <Icons.Help />,
      text: "Situação: ",
      data: <ShowProjectStatus type={situation} value={0} />,
    },
  ];

  const timeInfo = [
    {
      icon: <Icons.EventAvailable />,
      text: "Criado: ",
      data: FormatDate(created, "Não definido"),
    },
    {
      icon: <Icons.DateRange />,
      text: "Atualizado: ",
      data: FormatDate(updated, "Não definido"),
    },
  ];

  return (
    <>
      <List dense disablePadding style={{marginTop:"0.5rem"}}>
      {dataInfo.filter((r) => r.data !== null).map((value) => (
       <ListItem alignItems="start" key={value.text}>
       <ListItemIcon >{value.icon}</ListItemIcon>
       <ListItemText
       style={{alignItems: "start", textAlign:"start", margin: 0, padding: 0}}
       primary={<Typography variant="h6" component="span">{value.text}</Typography>}
       secondary={
        <Typography
          component="span"
         // variant="body2"
          style={{marginLeft: "0.5rem" }}
          color="textSecondary"
        >
        {value.data} 
        </Typography>
      }/>
      </ListItem>
    ))}
  </List>
  <Divider style={{margin: "0.5rem 0px 0.5rem 0px"}}  variant="inset" />
       <List dense disablePadding>
        {timeInfo.map((value) => (
          <ListItem  alignItems="start" key={value.text}>
          <ListItemIcon >{value.icon}</ListItemIcon>
          <ListItemText
          style={{alignItems: "start", textAlign:"start", margin: 0, padding: 0}}
          primary={<Typography variant="h6" component="span">{value.text}</Typography>}
          secondary={
           <Typography
             component="span"
            // variant="body2"
             style={{marginLeft: "0.5rem" }}
             color="textSecondary"
           >
           {value.data} 
           </Typography>
         }/>
         </ListItem>
       ))}
     </List>
    </>
  );
};
