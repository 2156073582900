import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Button,
  Grid,
  createStyles,
  makeStyles,
  TextField,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  InputAdornment,
  IconButton,
  Box,
} from "@material-ui/core";

import CustomDialog from "../../../Components/CustomDialog";
import { SelectApi } from "../../../Components/SelectsApi";
import {
  GET_SECRETARY,
  GET_LEVELUSER,
  POST_INSERT_USER,
} from "../../../services/options";
import { MaskCel, MaskPhone } from "../../../Components/Helper/FormatValues";
import useAxios from "../../../Context/Hooks/useAxios";
import { useSnackbar } from "notistack";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { UserContext } from "../../../Context/UserContext";
import { ButtonSuccess } from "../../../Components/ButtonSuccess";
import DefaultModal, { DefaultModalContent, DefaultModalActions } from "../../../Components/DefaultModal";

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    display: "grid",
    [theme.breakpoints.up("xs")]: {width: 350, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("sm")]: {width: 400, padding: theme.spacing(0, 1, 0, 1),},   
    [theme.breakpoints.up("md")]: {width: 450, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("lg")]: {width: 450, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("xl")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
  },
})
);

const initialValues = {
  form_login: "",
  form_password: "",
  form_confirmPassword: "",
  form_name: "",
  form_email: "",
  form_phone: "",
  form_cellPhone: "",
  responsibleSecretary: null,
  accessLevel: null,
  status: 1,
  boolResponsibleSecretary: 0,
};

const validationSchema = Yup.object().shape({
  form_login: Yup.string()
    .required("Obrigatório")
    .min(6, "O login deve ter pelo menos 6 caracteres"),

  form_password: Yup.string()
    .required("Obrigatório")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "A senha deve conter pelo menos 8 caracteres, uma maiúscula, um número e um caractere especial"
    ),
  form_confirmPassword: Yup.string()
    .oneOf([Yup.ref("form_password"), null], "As senhas não correspondem")
    .required("Obrigatório"),

    form_name: Yup.string()
    .required("Obrigatório")
    .min(4, "O nome deve ter pelo menos 4 caracteres"),
  responsibleSecretary: Yup.object().shape().required("Obrigatório").nullable(),
  accessLevel: Yup.object().shape().required("Obrigatório").nullable(),
  form_email: Yup.string().email("E-mail inválido").required("Obrigatório"),

  status: Yup.number().required("Obrigatório"),
  boolResponsibleSecretary: Yup.number().required("Obrigatório"),
});

export default function NewUser({ open, setOpen, updateData }) {
  const { loading, error, request } = useAxios();
  const { enqueueSnackbar } = useSnackbar();

  const submit = async (values, { setSubmitting }) => {
    const buffer = {
      login:                values.form_login,
      password:             values.form_password,
      confirmPassword:      values.form_confirmPassword,
      name:                 values.form_name,
      email:                values.form_email,
      phone:                values.form_phone,
      cellPhone:            values.form_cellPhone,
      responsibleSecretary: values.responsibleSecretary,
      accessLevel:          values.accessLevel,
      status:               values.status,
      boolResponsibleSecretary: values.boolResponsibleSecretary,
    };
    console.log(JSON.stringify(buffer, null, 2))
    await request(POST_INSERT_USER(buffer)).then(r=>{
      const {data} = r;
      if(data.status === "success"){
        updateData();
        setSubmitting(false);
        enqueueSnackbar("Usuário criado!!!", { variant: "success" });
        setOpen(false);
      }else{
        setSubmitting(false);
        enqueueSnackbar(`${data?.msg}`, { variant: "Error" })
      }
     }
    ).catch(e=>{
      setSubmitting(false);
      enqueueSnackbar(`Erro ${e.message}`, { variant: "Error" });}
    );
  };

  return (
    <DefaultModal
      open={open}
      handleClose={()=>setOpen(false)}
      scroll="body"
      title="Novo usuário"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {(props) => <Forms {...props} />}
      </Formik>
    </DefaultModal>
  );
}

const Forms = (props) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const {privilege} = React.useContext(UserContext);

  const classes = useStyles();
  const {
    values: {
      form_login,
      form_password,
      form_confirmPassword,
      form_name,
      form_email,
      form_phone,
      form_cellPhone,
      status,
      responsibleSecretary,
      boolResponsibleSecretary,
      accessLevel,
    },
    touched,
    errors,
    dirty,
    isSubmitting,
    handleChange,
    setFieldValue,
    handleSubmit,
    handleReset,
  } = props;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <DefaultModalContent dividers>
        <Grid container  className={classes.root} spacing={2}>  
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            maxRows={5}
            variant="outlined"
            size="small"
            id="form_login"
            name="form_login"
            label="Usuário" 
            inputProps={{ autoComplete: "off" }}
            autoComplete="off"
            onChange={handleChange}
            value={form_login}
            error={touched.form_login && Boolean(errors.form_login)}
            helperText={touched.form_login && errors.form_login}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            id="form_password"
            name="form_password"
            type={showPassword ? "text" : "password"}
            label="Senha"
            inputProps={{ autoComplete: "off" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            onChange={handleChange}
            value={form_password}
            error={touched.form_password && Boolean(errors.form_password)}
            helperText={touched.form_password && errors.form_password}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            name="form_confirmPassword"
            id="form_confirmPassword"
            label="Confirme a senha"
            type={showConfirmPassword ? "text" : "password"}
            inputProps={{ autoComplete: "off" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            onChange={handleChange}
            value={form_confirmPassword}
            error={touched.form_confirmPassword && Boolean(errors.form_confirmPassword)}
            helperText={touched.form_confirmPassword && errors.form_confirmPassword}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            maxRows={5}
            variant="outlined"
            size="small"  
            id="form_name"
            name="form_name"
            label="Nome"
            inputProps={{ autoComplete: "off" }}
            autoComplete="off"
            onChange={handleChange}
            value={form_name}
            error={touched.form_name && Boolean(errors.form_name)}
            helperText={touched.form_name && errors.form_name}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            id="form_email"
            name="form_email"
            label="Email"
            inputProps={{ autoComplete: "off" }}
            autoComplete="off"
            onChange={handleChange}
            value={form_email}
            error={touched.form_email && Boolean(errors.form_email)}
            helperText={touched.form_email && errors.form_email}
          />
        </Grid>

       <Grid item xs={12} style={{display:"flex"}}>  
        <Grid item xs={6}>
          <TextField
            style={{width: "90%"}}
            variant="outlined"
            size="small"
            fullWidth
            id="form_phone"
            name="form_phone"
            label="Telefone"
            InputProps={{ inputComponent: MaskPhone }}
            inputProps={{ autoComplete: "off" }}
            onChange={handleChange}
            value={form_phone}
            error={touched.form_phone && Boolean(errors.form_phone)}
            helperText={touched.form_phone && errors.form_phone}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            id="form_cellPhone"
            name="form_cellPhone"
            label="Celular"
            InputProps={{ inputComponent: MaskCel }}
            inputProps={{ autoComplete: "off" }}
            onChange={handleChange}
            value={form_cellPhone}
            error={touched.form_cellPhone && Boolean(errors.form_cellPhone)}
            helperText={touched.form_cellPhone && errors.form_cellPhone}
          />
        </Grid>
       </Grid>  

        <Grid item xs={12}>
          <SelectApi
            id="responsibleSecretary"
            name="responsibleSecretary"
            onChange={(e, value) =>
              setFieldValue("responsibleSecretary", value || null)
            }
            value={responsibleSecretary}
            config={GET_SECRETARY()}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              variant: "outlined",
              label: "Secretaria",
            }}
            error={
              touched.responsibleSecretary &&
              Boolean(errors.responsibleSecretary)
            }
            helperText={
              touched.responsibleSecretary && errors.responsibleSecretary
            }
          />
        </Grid>

        <Grid item xs={12}>
          <SelectApi
            id="accessLevel"
            name="accessLevel"
            onChange={(e, value) => setFieldValue("accessLevel", value || null)}
            value={accessLevel}
            getOptionDisabled={(option) => (privilege.collaboratorNv1 && (option.id === '1' || option.id === '2'))}
            config={GET_LEVELUSER()}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              variant: "outlined",
              label: "Nível de acesso",
            }}
            error={touched.accessLevel && Boolean(errors.accessLevel)}
            helperText={touched.accessLevel && errors.accessLevel}
          />
        </Grid>

       <Grid item xs={12} style={{display:"flex"}}>
        <Grid item xs={6}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Status</FormLabel>
            <RadioGroup
              aria-label="status"
              name="status"
              id="status"
              value={status}
              onChange={(e, value) => {
                setFieldValue("status", Number(value));
              }}
            >
              <FormControlLabel value={1} control={<Radio />} label="Ativo" />
              <FormControlLabel value={0} control={<Radio />} label="Inativo" />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Responsável pela secretaria?
            </FormLabel>
            <RadioGroup
              aria-label="boolResponsibleSecretary"
              name="boolResponsibleSecretary"
              id="boolResponsibleSecretary"
              value={boolResponsibleSecretary}
              onChange={(e, value) => {
                setFieldValue("boolResponsibleSecretary", Number(value));
              }}
            >
              <FormControlLabel value={1} control={<Radio />} label="Sim" />
              <FormControlLabel value={0} control={<Radio />} label="Não" />
            </RadioGroup>
          </FormControl>
        </Grid>
       </Grid> 

       </Grid>
       </DefaultModalContent>
        
       <DefaultModalActions style={{marginRight: "0.7rem"}}> 
          <ButtonSuccess
             style={{width: 120}}
             variant="contained"
             color="primary"
             type="submit"
             success={false}
             loading={isSubmitting}
            > Criar
            </ButtonSuccess>
     </DefaultModalActions>
      
    </form>
  );
};
