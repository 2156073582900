import React from "react";
import {
  createStyles,
  makeStyles,
  Grid,
} from "@material-ui/core";

import Form from "./Form";
import { Formik } from "formik";
import api from "../../../../services/Api";
import { useSnackbar } from "notistack";


const initialProjectValues = {
  name: "", 
  group: null,
  axis: null,
  responsible: null,
  responsibleSecretary: null,
  status: null,
  initDate : null,
  deadLine : null,
  isRoutine: false,
  isGovernmentPlan: false
  // detail: {
  //   status: false,
  //   finances: false,
  //   physicalProgress: false,
  //   progress: false,
  // },
};

const openFileInNewTab = async (response/*: AxiosResponse*/, filename/*: string*/ = 'download') => {
  const {data} = response;
  if (!(data instanceof Blob)) return;
  const blob = new Blob([data], { type: 'application/pdf' });
  // IE
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(data, filename);
    return;
  }
  // Chrome, FF
  const fileUrl = URL.createObjectURL(blob);
  const w = window.open(fileUrl, '_blank', );
  w && w.focus();
};

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    list: {
      width: "auto",
      margin: "0px auto",
    },
    showInfo: {
      width: "auto",
    },
  })
);

const POST_REPORT = (body) => {
  return {
    url: `pdf/projects.php`,
    method: 'post',
    responseType: 'blob', // important
    data: body,
  };
};

const ReportsPanel = () => {
  const { enqueueSnackbar } = useSnackbar();

  const submit = async (values, { setSubmitting }) => {
       const buffer ={
        name                : values.name, 
        group               : values.group !== null?values.group?.id:0,
        axis                : values.axis  !== null?values.axis?.id:0,
        initDate            : values.initDate,
        deadLine            : values.deadLine,
        status              : values.status === null?0:values.status.id,
        responsible         : values.responsible === null?0:values.responsible.id,
        responsibleSecretary: values.responsibleSecretary === null?0:values.responsibleSecretary.id,
        isRoutine           : values.isRoutine ?1:0,
        isGovernmentPlan    : values.isGovernmentPlan ?1:0,
       // type:   values.type,
      //  detail: values.detail,
      }
      console.log(JSON.stringify(buffer, null, 2))
      
      await api(POST_REPORT(buffer)).then((response) => {
        openFileInNewTab(response, "teste.pdf")
        setSubmitting(false);  
      }).catch(e=>{
        setSubmitting(false);
        enqueueSnackbar(`Erro ao visualizar relatório !!!`, { variant: "warning" });
      });
  };


  return (
    <Grid container>
      <Grid item xs={12}>
        <Formik
         initialValues={initialProjectValues}
         onSubmit={submit}
         >
          {(props) => <Form {...props} />}
        </Formik>
      </Grid>
    </Grid>
  );
};


/*

        group:       values.group?.id !== null?values.group?.id:0,
        status:      values.status.length > 0 ? values.status.map((valor)=> Number(valor.id)): 0,
        responsible: values.responsible.length > 0 ? values.responsible.map((valor)=> Number(valor.id)): 0,
        responsibleSecretary: values.responsibleSecretary.length > 0 ? values.responsibleSecretary.map((valor)=> Number(valor.id)): 0,
        axis:   values.axis.length > 0 ? values.axis.map((valor)=> Number(valor.id)): 0,


nome {talvez}
rotina
grupo
eixo
data inicial/limite/concluido
valor previsto/executado
secretaria responsavel
responsavel
status (Concluído, Em andamento, Em atraso, Não iniciado, Cancelado)
*/

export default ReportsPanel;
 