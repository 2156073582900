import React from "react";
import { convertToHTML } from 'draft-convert';
import { Formik } from "formik";
import * as Yup from "yup";
import { makeStyles, createStyles} from "@material-ui/core/styles";
import { GET_RQSTFORMINFO_TASK, POST_UPDATE_TASK } from "../../../../services/options";
import api from "../../../../services/Api";
import { useSnackbar } from "notistack";
import { ButtonSuccess } from "../../../../Components/ButtonSuccess";
import { TextEditor } from "../../../../Components/Editor";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from "../../../../Components/DefaultModal";
import { DialogContentText, Typography} from "@material-ui/core";
import { POST_UPDATE_5W2H } from "../../../../services/options";
 
 const validationSchema = Yup.object().shape({
  justification: Yup.string().required("Obrigatório").min(8, "Obrigatório"),
 });

 const initial = {
  id            : 0,
  riskId        : {id: 0, name: "teste"},
  whereField    : "",
  what          : "",
  why           : "",
  who           : "",
  initDate      : null,
  deadLine      : null,
  executedDate  : null,
  predictedValue: null,
  executedValue : null,
  how           : "",
  status        : {id: 0, name: "teste"},
  canceled      : 0,
  stoped        : 0,
  justification : "",
 }

export function Cancel5W2H({id, open, setOpen, updateData}) {
    const [data, setData] = React.useState(null)
    const { enqueueSnackbar } = useSnackbar();

    const rqstInfo = async ()=>{
       setData(initial)
    }

    React.useEffect(()=>{
     if(open) rqstInfo()   
    },[open])

    const submit = async(values, { setSubmitting }) => {
      const buffer = {
        id            : values.id,
        riskId        : values.risk.id,
        what          : values.what,
        why           : values.why,
        whereField    : values.whereField,
        who           : values.who,
        initDate      : values.initDate,
        deadLine      : values.deadLine,
        executedDate  : values.executedDate,
        predictedValue: !values.predictedValue ? 0 : values.predictedValue,
        executedValue : !values.executedValue   ? 0 : values.executedValue,
        how           : values.how,
        statusId      : values.status.id,
        canceled      : 1,
        stoped        : 0,
        justification : values.justification,
      }  
     await api(POST_UPDATE_5W2H(buffer)).then(r=>{
       const {data} = r;
       if(data.status === "success"){
        enqueueSnackbar("Plano de ação cancelado !!!", { variant: "success" });
        updateData()
        setOpen(false);
       }else{
        setSubmitting(false);
        enqueueSnackbar(`Erro ao cancelar plano de ação`, { variant: "Error" })
       }
      }).catch(r=>{
        setSubmitting(false);
        enqueueSnackbar(`Erro ao cancelar plano de ação`, { variant: "Error" })
      })
    }

    const closeModal =()=>{
      setOpen(false)
    }
    
    return (
      <DefaultModal
        open={open}
        loading={data === null}
        handleClose={closeModal}
        title="Cancelar plano de ação">
        <Formik
          initialValues={data}
          validationSchema={validationSchema}
          onSubmit={submit}
        >
          {(props) => <Forms {...props} />}
        </Formik>
      </DefaultModal>
    );
  }

  const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("xs")]: { maxWidth: 380 },
      [theme.breakpoints.up("sm")]: { maxWidth: 450 },
      [theme.breakpoints.up("md")]: { maxWidth: 500 },
      [theme.breakpoints.up("lg")]: { maxWidth: 500 },
      [theme.breakpoints.up("xl")]: { maxWidth: 550 },
    },
  })
);

const Forms = (props) => {
  const classes = useStyles();

  const { values, errors, touched, handleSubmit, isSubmitting, setFieldValue } = props;
  return (
    <form onSubmit={handleSubmit} noValidate>
      <DefaultModalContent dividers className={classes.root}>
        <DialogContentText>
        <Typography variant="subtitle1" gutterBottom>
          Justifique o cancelamento do plano de ação!
        </Typography>
        </DialogContentText>
        <TextEditor
          id="justification"
          name="justification"
          label="Justificativa"
          onChange={(event) => {setFieldValue("justification", convertToHTML(event.getCurrentContent()))}}
          value={values.justification}
          error={touched.justification && Boolean(errors.justification)}
          helperText={touched.justification && errors.justification}
        />
      </DefaultModalContent>
      <DefaultModalActions >
        <ButtonSuccess
          variant="contained"
          color="primary"
          type="submit"
          success={false}
          loading={isSubmitting}
        >
          Confirmar
        </ButtonSuccess>
      </DefaultModalActions>
    </form>
  );
};